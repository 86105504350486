
import React, { useState } from 'react'
import "react-datepicker/dist/react-datepicker.css";

import { Link } from "react-router-dom"
import { useLoadScript } from "@react-google-maps/api";

import "./store.css"
import PageLocation from "../assets/PageLocation.asset";
import LocMap from "../assets/MapLocation"
const libraries = ["places"]; 

const CreateStore = (  ) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBoGl2szeBzVnyWJexC8b4OLT1OylQ1UyQ",
        libraries: libraries,
    });

    return ( 
    <div className='create-container' style={{ marginLeft : "20rem" }}>
    <div style={{ marginTop : "3rem" }}>
        <PageLocation header="Farmer Shop" model="Store" address="Select Location"/>
        <hr/>
    </div>
    <div>
        { isLoaded ? <LocMap /> : <div>Loading...</div> }
    </div>
    </div>
    )
}

export default CreateStore;
