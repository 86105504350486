import create from "zustand";
import { persist } from "zustand/middleware";

const store = (set) => ({
  lat : 0,
  lng : 0,
  setLat : ( lat ) => set( { lat } ),
  setLng : ( lng ) => set( { lng } ),
  storeData: {},
  setStoreData: ( storeData ) => set( { storeData } )
});

const configStore = create(persist(store));

export default configStore;
