
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { RiDeleteBin5Line } from "react-icons/ri"
import { AiFillEdit } from "react-icons/ai"
import axios from "axios";

import PageLocation from "../assets/PageLocation.asset";
import SearchBar from "./SerachBar.component"
import Table from "../assets/Table.asset"
import SideBarStore from "../zastand/SideBar.zastand"
import StoreDataZastand from "../zastand/location.zastand"


const Customer = () => {
  const navigate = useNavigate();
  const { setStoreData, storeData } = StoreDataZastand()
  const [marginForSideBar, setMargin] = useState("0%")
  const [leftPopUp, setLeftPopUp] = useState("33rem")
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const sidebarStatus = SideBarStore((state) => state.sideBar);
  useEffect(() => { 
    if( sidebarStatus ){
      setMargin("14%")
      setLeftPopUp( "37rem" )
    }else{
      setMargin("0%")
      setLeftPopUp( "33rem" )
    }
   }, [sidebarStatus])

  const fetchUsers = async (page, size = perPage, searchQuery = "" ) => {
    setLoading(true);
    const host = "http://193.203.162.181:4000"
    const url = host + `/store?page=${page}&limit=${size}` + searchQuery
    const response = await axios.get(url);

    setData(response.data.data);
    setTotalRows(response.data.total);
    setLoading(false);
  };
  useEffect(() => { fetchUsers(1) }, []);

  const handleDelete = useCallback(
    row => async () => {
      const host = "http://193.203.162.181:4000"
      const url = host + `/emp?empId=${row.id}`
      // const { data } = await axios.delete( url );
      if( !data.status ) alert( "Server Error" )
      else alert ( data.message )
      fetchUsers( currentPage, perPage )
    },
    [currentPage, perPage, totalRows]
  );
  const handleEdit = useCallback(
    row => async () => {
      handleSubmitEdit(row)
    },
    [ currentPage, perPage, totalRows ]
  );
  const columns = useMemo(
    () => [
      {
        name: "No.",
        selector: (row, i) => i + 1,
        width: "5%"
      },
      {
        name: "Store Id",
        selector: (row, i) => row.id,
        width: "10%"
      },
      {
        name: "Name",
        selector: (row, i) => row.name,
        width: "10%"
      },
      {
        name: "Country",
        selector: (row, i) => row.country,
      },
      {
        name: "State",
        selector: (row, i) => row.state,
      },
      {
        name: "City",
        selector: (row, i) => row.city,
      },
      {
        name: "Total Products",
        selector: (row, i) => row.productCount,
      },
      {
        name: "Total employees",
        selector: (row, i) => row.empCount,
      },
      {
        cell: row => {
          return (<>
            <button class="btn btn-sm btn-warning action-edit" onClick={handleEdit(row)}><AiFillEdit/></button>
            <Link to={`/main/store/emp/${row.id}`}><button class="btn btn-sm btn-info action-edit" >Employee</button></Link>
            <Link to={`/main/store/product/${row.id}`} ><button class="btn btn-sm btn-primary action-edit" >Products</button></Link>
            <button class="btn btn-sm btn-danger action-delete" onClick={handleDelete(row)}><RiDeleteBin5Line /></button>
          </>)
        },
        width: "20%"
      }
    ],
    [handleDelete]
  );
  const handlePageChange = page => {
    fetchUsers(page);
    setCurrentPage(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    fetchUsers(page, newPerPage);
    setPerPage(newPerPage);
  };
  const handleSearchBar = async ( searchQuery ) => {
    fetchUsers( 1, 10, searchQuery );
  }
  
  const handleSubmitEdit = (row) => {
    setStoreData(row)
    navigate( "/main/store/create" )
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: marginForSideBar, padding: "2rem"}}>
      <div style={{ width : "100%", height: "100%" }}>
      <PageLocation header="Farmer Shop" model="Stores" address="Store List"/>
      <SearchBar model="Store" searchquery={handleSearchBar} />
      <Table 
        columns={columns} 
        data={data} 
        progressPending={loading} 
        paginationTotalRows={totalRows} 
        paginationDefaultPage={currentPage} 
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        />
      </div>
    </div>
  )
};

export default Customer;
