import React from "react"
import { VscSignOut } from "react-icons/vsc"
import { AiFillSetting } from "react-icons/ai"
import { Link } from "react-router-dom"

import "./dropDown.css"

const itemStyle = {
    height: "240px",
    display: "flex",
    flexDirection: "column",
    padding : "5%"
}

const Dropdown = (props) => {
  const userName = localStorage.getItem( "name" )
  const role = localStorage.getItem( "roleName" )
  const signOutHandler = () => {
    localStorage.clear()
  }
  return (
    <div className="dropDown bottom">
      <div style={ itemStyle }>
        <div >
          <h5>{userName}</h5>
          <p>{role}</p>
          <hr/>
        </div>
        <Link to="/" onClick={signOutHandler} className="dropDownlinks" ><VscSignOut/> Sign Out</Link>
        <hr/>
        <Link className="dropDownlinks" ><AiFillSetting/> Settings</Link>
      </div>
    </div>
  )
};

export default Dropdown;
