
import React, { useState, useEffect, useRef } from 'react'
import { useFormik } from "formik";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import axios from 'axios';
import { Link } from "react-router-dom"

import "./products.css"
import PageLocation from "../assets/PageLocation.asset";
import { units } from "../assets/config"
const jwtToken = localStorage.getItem( "token" )
const host = "http://193.203.162.181:4000"

const CreateProduct = ( { popUp } ) => {
    const formRef = useRef(null);
    const resetFileInput = () => {
        if (formRef.current) formRef.current.reset(); // Reset the form
    };
    const [ brandData, setBrands ] = useState( [] )
    const [ categoryData, setCategory ] = useState( [] )
    const [ similarProductData, setSimilarProducts ] = useState( [] )
    const [ subCategoryData, setSubCategory ] = useState( [] )
    const [ countryData, setCountryData] = useState( [] )
    const [ stateData, setStateData ] = useState( [] )
    const [ cityData, setCityData ] = useState( [] )

    const create = async( reqBody ) => {
        const url = host + `/products`
        const { data } = await axios.post( url, reqBody ,{ headers : { token : jwtToken } });
        if( !data.status ) alert( "Server Error" )
        else alert ( data.message )
    }
    const formik = useFormik({
        initialValues: {
          name: "",
          brandId : "",
          categoryId: "",
          subCategoryId : "",
          similarProductId : "",
          expiry: "",
          maxOrderQuantity: "",
          stock_quantity: "",
          newPrice : "",
          oldPrice : "",
          unit : "",
          countryId : "",
          stateId : "",
          cityId : "",
          thumbnail : {},
          description: ""
        },
        validationSchema: Yup.object(
            {
                name: Yup.string()
                .required('A Name is required')
            }
        ),
        onSubmit: async( valueObj ) => {
            console.log(valueObj);
            const formData = new FormData();
            for( var key in valueObj ) formData.append( key, valueObj[key] )
            await create( formData )
            resetFileInput()
            formik.resetForm()
        }
    });
    useEffect( ()=> {
        axios.get( host + "/brands/dropDown", { headers : { token : jwtToken } } )
        .then( ( { data } ) => { setBrands( data.data ) } )
        .catch( err => alert( err ))

        axios.get( host + "/category/dropDown", { headers : { token : jwtToken } } )
        .then( ( { data } ) => { setCategory( data.data ) } )
        .catch( err => alert( err ))

        axios.get( host + "/similarProducts/dropDown", { headers : { token : jwtToken } } )
        .then( ( { data } ) => { setSimilarProducts( data.data ) } )
        .catch( err => alert( err ))

        axios.get( host + "/location/country", { headers : { token : jwtToken } } )
        .then( ( { data } ) => { setCountryData( data.data ) } )
        .catch( err => alert( err ))
    }, [] )

    useEffect( ()=> {
        if( formik.values.categoryId ) {
             axios.get( host + `/subCategory/dropDown?categoryId=${formik.values.categoryId}`, { headers : { token : jwtToken } } )
            .then( ( { data } ) => { setSubCategory( data.data ) } )
            .catch( err => alert( err ))
        }
    }, [formik.values.categoryId] )

    useEffect( ()=> {
        if( formik.values.countryId ) {
             axios.get( host + `/location/states?countryId=${formik.values.countryId}`, { headers : { token : jwtToken } } )
            .then( ( { data } ) => { setStateData( data.data ) } )
            .catch( err => alert( err ))
        }
    }, [formik.values.countryId] )
    useEffect( ()=> {
        if( formik.values.stateId ) {
             axios.get( host + `/location/cities?stateId=${formik.values.stateId}`, { headers : { token : jwtToken } } )
            .then( ( { data } ) => { setCityData( data.data ) } )
            .catch( err => alert( err ))
        }
    }, [formik.values.stateId] )

    return ( 
        <div className='create-container' style={{ marginLeft : "5rem" }}>
        <div>
            <PageLocation header="Farmer Shop" model="Products" address="Product Create"/>
            <hr/>
        </div>
        <form onSubmit={formik.handleSubmit} ref={formRef}>        
            <div className='createInput'>
                <div className='emp-inputBox'>
                    <label><h5>Product Name</h5><span class="required">*</span></label>
                    <div class="">
                        <input type="text" name="name" class="form-control" value={formik.values.name} onChange={formik.handleChange} placeholder="Please Enter Name" autoComplete="off"/>    
                        {( formik.errors.name && formik.touched.name  ) ? ( <p>{formik.errors.name}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Brand</h5><span class="required">*</span></label>
                    <div class="product-select">
                        <select type="text" name="brandId" class="form-control" value={formik.values.brandId} onChange={formik.handleChange} >
                            <option>Please Select Brand</option>    
                            { brandData && brandData.map( (brand, index) => <option key={index} value={brand["_id"]} >{brand.name}</option> ) }
                        </select>    
                        {( formik.errors.brandId && formik.touched.brandId  ) ? ( <p>{formik.errors.brandId}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Category</h5><span class="required">*</span></label>
                    <div class="product-select">
                        <select type="text" name="categoryId" class="form-control" value={formik.values.categoryId} onChange={formik.handleChange} >
                            <option>Please Select Category</option>    
                            { categoryData && categoryData.map( (category, index) => <option key={index} value={category["_id"]} >{category.name}</option> ) }
                        </select>    
                        {( formik.errors.categoryId && formik.touched.categoryId  ) ? ( <p>{formik.errors.categoryId}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Sub Category</h5><span class="required">*</span></label>
                    <div class="product-select">
                        <select type="text" name="subCategoryId" class="form-control" value={formik.values.subCategoryId} onChange={formik.handleChange} >
                            <option>Please Select Sub-Category</option>    
                            { subCategoryData && subCategoryData.map( (subCategory, index) => <option key={index} value={subCategory["_id"]} >{subCategory.name}</option> ) }
                        </select>    
                        {( formik.errors.subCategoryId && formik.touched.subCategoryId  ) ? ( <p>{formik.errors.subCategoryId}</p> ) : <p></p>}
                    </div>
                </div>
            </div>
            <div className='createInput'>
                <div className='emp-inputBox'>
                    <label><h5>Similar Tag</h5><span class="required">*</span></label>
                    <div class="product-select">
                        <select type="text" name="similarProductId" class="form-control" value={formik.values.similarProductId} onChange={formik.handleChange} >
                            <option>Please Select Similar Tag</option>    
                            { similarProductData && similarProductData.map( (similarProduct, index) => <option key={index} value={similarProduct["_id"]} >{similarProduct.name}</option> ) }
                        </select>    
                        {( formik.errors.similarProductId && formik.touched.similarProductId  ) ? ( <p>{formik.errors.similarProductId}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Expiry</h5> <span class="required">*</span></label>
                    <div class="">
                        <input type="text" name="expiry" class="form-control" value={formik.values.expiry} onChange={formik.handleChange} placeholder="Please Enter Expiry" autoComplete="off"/>    
                        {( formik.errors.expiry && formik.touched.expiry  ) ? ( <p>{formik.errors.expiry}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Max order Quantity</h5> <span class="required">*</span></label>
                    <div class="">
                        <input type="text" name="maxOrderQuantity" class="form-control" value={formik.values.maxOrderQuantity} onChange={formik.handleChange}  placeholder="Please Enter Max Order Limit" autoComplete="off"/>    
                        {( formik.errors.maxOrderQuantity && formik.touched.maxOrderQuantity  ) ? ( <p>{formik.errors.maxOrderQuantity}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Stock Available</h5><span class="required">*</span></label>
                    <div class="">
                        <input type="text" name="stock_quantity" class="form-control" value={formik.values.stock_quantity} onChange={formik.handleChange} placeholder="Please Enter Stock Available" autoComplete="off"/>    
                        {( formik.errors.stock_quantity && formik.touched.stock_quantity  ) ? ( <p>{formik.errors.stock_quantity}</p> ) : <p></p>}
                    </div>
                </div>
            </div>
            <div className='createInput'>
                <div className='emp-inputBox'>
                    <label><h5>Price</h5><span class="required">*</span></label>
                    <div class="">
                        <input type="text" name="newPrice" class="form-control" value={formik.values.newPrice} onChange={formik.handleChange} placeholder="Please Enter Price" autoComplete="off"/>    
                        {( formik.errors.newPrice && formik.touched.newPrice  ) ? ( <p>{formik.errors.newPrice}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Old Price</h5><span class="required">*</span></label>
                    <div class="">
                        <input type="text" name="oldPrice" class="form-control" value={formik.values.oldPrice} onChange={formik.handleChange} placeholder="Please Enter Old Price" autoComplete="off"/>    
                        {( formik.errors.oldPrice && formik.touched.oldPrice  ) ? ( <p>{formik.errors.oldPrice}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Unit</h5><span class="required">*</span></label>
                    <div class="product-select">
                        <select type="text" name="unit" class="form-control" value={formik.values.unit} onChange={formik.handleChange} >
                            <option>Please Select Unit</option>    
                            { units && units.map( (country, index) => <option key={index} value={country["value"]} >{country.lable}</option> ) }
                        </select>    
                        {( formik.errors.unit && formik.touched.unit  ) ? ( <p>{formik.errors.unit}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Thumbnail</h5> <span class="required">*</span></label>
                    <div style={{ width : "95%" }}>
                        <input type="file" name="thumbnail" class="form-control"  onChange={(event) => { formik.setFieldValue('thumbnail', event.currentTarget.files[0])}} />    
                        {( formik.errors.thumbnail && formik.touched.thumbnail  ) ? ( <p>{formik.errors.thumbnail}</p> ) : <p></p>}
                    </div>
                </div>
            </div>
            <div className='createInput'>
                <div className='emp-inputBox'>
                    <label><h5>Origin Country</h5><span class="required">*</span></label>
                    <div class="product-select">
                        <select type="text" name="countryId" class="form-control" value={formik.values.countryId} onChange={formik.handleChange} >
                            <option>Please Select Country</option>    
                            { countryData && countryData.map( (country, index) => <option key={index} value={country["id"]} >{country.name}</option> ) }
                        </select>    
                        {( formik.errors.countryId && formik.touched.countryId  ) ? ( <p>{formik.errors.countryId}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Origin State</h5><span class="required">*</span></label>
                    <div class="product-select">
                        <select type="text" name="stateId" class="form-control" value={formik.values.stateId} onChange={formik.handleChange} >
                            <option>Please Select State</option>    
                            { stateData && stateData.map( (state, index) => <option key={index} value={state["id"]} >{state.name}</option> ) }
                        </select>    
                        {( formik.errors.stateId && formik.touched.stateId  ) ? ( <p>{formik.errors.stateId}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Origin City</h5><span class="required">*</span></label>
                    <div class="product-select">
                        <select type="text" name="cityId" class="form-control" value={formik.values.cityId} onChange={formik.handleChange} >
                            <option>Please Select City</option>    
                            { cityData && cityData.map( (city, index) => <option key={index} value={city["id"]} >{city.name}</option> ) }
                        </select>    
                        {( formik.errors.cityId && formik.touched.cityId  ) ? ( <p>{formik.errors.cityId}</p> ) : <p></p>}
                    </div>
                </div>
                
            </div>       
            <div className='createInput'>
                <div className='emp-inputBox'>
                    <label><h5>Description</h5> <span class="required">*</span></label>
                    <div style={{ width : "100%"}}>
                        <textarea type="text" name="description" style={{ height : "7rem" }} class="form-control" value={formik.values.description} onChange={formik.handleChange} placeholder="Please Enter Description about Product" autoComplete="off"/>    
                        {( formik.errors.d && formik.touched.description  ) ? ( <p>{formik.errors.description}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='product-inputBox-submit'>
                    <div className="empty-space"></div>
                    <button type="submit"  class="btn btn-success">Save</button>
                    &nbsp;&nbsp;
                    <Link to="/main/products" onClick={ ()=>popUp() } ><button type="submit" class="btn btn-danger">Cancel</button></Link>
                </div>
            </div>
        </form>
        </div>  
    )
}

export default CreateProduct;
