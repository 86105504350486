import React from "react"
import { Link } from "react-router-dom"
import { useFormik } from "formik";
import * as Yup from "yup";

import "./similarProduct.css"

const TableInput = ({ model, searchquery, popUp }) => {
    const formik = useFormik({
        initialValues: {
          categoryId: "",
          name: ""
        },
        validationSchema: Yup.object({
            categoryId: Yup.string()
            .min(2, "Mininum 2 characters"),
            name: Yup.string()
            .min(2, "Mininum 2 characters")
        }),
        onSubmit: valueObj => {
            let searchQueryString = ""
            for (var key in valueObj) {
                if( valueObj[key] != "" ) searchQueryString = searchQueryString + `&${key}=${valueObj[key]}` 
            }
            searchquery( searchQueryString )
            formik.resetForm()
        }
      });
  return ( 
    <div className="empSearch">
    <form onSubmit={formik.handleSubmit}>
    <table class="table table-bordered">
    <tbody>
        <tr>
            <td>Name</td>
            <td>Action</td>
        </tr>
        <tr>
        <td>
            <input type="text" name="name" className="form-control" value={formik.values.name} onChange={formik.handleChange} placeholder="Please Enter Name" autoComplete="off"/>
            {formik.errors.name && formik.touched.name && ( <p>{formik.errors.name}</p> )}
        </td>
        <td class="search-action">
            <input type="submit" name="search" value="Search" className="btn btn-sm btn-primary"/>
            <button type="button" class="btn btn-sm btn-success" onClick={()=>popUp()} >+Add</button>                    
        </td>
        </tr>
    </tbody>
    </table>
    </form>
    </div>
   )
};

export default TableInput;
