import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, Outlet } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import { BsBell } from "react-icons/bs"
import { IconContext } from 'react-icons/lib';
import axios from 'axios';
import Avatar from 'react-avatar';
import { IoMdArrowDropdown } from "react-icons/io"
import { VscGitPullRequestGoToChanges, VscRequestChanges } from "react-icons/vsc"
import { BsFillBagFill, BsFillBagPlusFill } from "react-icons/bs"
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import BlurBox from "../tools/BlurBox"

import { SidebarData } from './SideBarData';
import SubMenu from './SubMenu';
import SideBarStore from "../zastand/SideBar.zastand"
import SocketDataStore from "../zastand/socketData.zastand"
import UserDataStore from "../zastand/user.zastand"
import Dropdown from './dropDown';
import socket from "../../socket"
import "./sidebar.css"

const Nav = styled.div`
  background: #15171c;
  height: 10%;
  display: flex;
  position: fixed;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #15171c;
  width: 250px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const RightSidebarWrap = styled.div`
  width: 100%;
  height: 100%
  display : flex;
  align-content: center;
  flex-direction: column;
`;

const RightSidebarCard = styled.div`
  background : #A7D397;
  width: 85%;
  display : flex;
  border-radius : 1rem;
  align-content: flex-start;
  flex-direction: column;
  margin-left: 1rem;
  margin-top: 1rem;
  // margin-bottom: .5rem;
  padding: 1rem;
`;

const RightSidebarNav = styled.nav`
  background: #15171c;
  width: 15%;
  height: 92%;
  margin-top: 4%;
  display : ${({ rightSidebar }) => ( rightSidebar ? 'flex' : 'none')};
  justify-content: center;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  right: 0px;
  transition: 350ms;
  z-index: 10;
  padding: .5rem;
`;

const Sidebar = () => {
  // const socket = useContext(SocketContext);
  const [ empNtf, setEmpNft ] = useState( false )
  const setSidebar = SideBarStore((state) => state.setSideBar);
  const sidebar = SideBarStore((state) => state.sideBar);

  //
  const [ dataState, setData ] = useState( [ ] )
  const [ popUp, setPopUp ] = useState( false )
  const [ popData, setPopData ] = useState( {} )
  const [ email, setEmail ] = useState( "" )
 
  const popUpHandler = async( data ) => {
    setPopData( data )
    setPopUp(!popUp)
  }
  
  const blurBoxStyle = {
    top : "12rem",
    left: "45rem",
    position:"fixed", 
    width: "30rem",
    height: "25rem", 
    backgroundColor: "black", 
    borderRadius: ".8rem"
  }
  //
  const setRightSidebar = SideBarStore((state) => state.setRightSideBar);
  const rightSidebar = SideBarStore((state) => state.rightSideBar);

  const setNavbar = SideBarStore((state) => state.setNavBar);
  const navBar = SideBarStore((state) => state.navBar);

  const showSidebar = () => setSidebar(!sidebar);
  const showRightSidebar = () => {
    setRightSidebar(!rightSidebar);
    setEmpNft( false )
  }
  const showNavbar = () => setNavbar( !navBar );

  const { empRequestData, setWholeNftData } = SocketDataStore();
  
  socket.on("cEmpRequest", (data) => {
    console.log("cEmpResponse+++++++++++++++++++");
    console.log(data);
    const newData = { empId : data.empId, mobile: data.mobile, name : data.name, roleName: data.roleName, roleId: data.roleId, storeId : data.storeId, privateEmail : data.privateEmail }
    setWholeNftData( [ newData, ...empRequestData ] )
    setEmpNft( true )
  })

  const popUpRequestHandler = async( ) => {
    console.log( popData );
    const host = "http://193.203.162.181:4000"
    const url = host + "/emp/assignMail"
    const responseData = await axios.post( url, { email, empId : popData.empId } )
    alert( JSON.stringify( responseData.data ) )
    setPopUp(!popUp)
    setEmail("")
    const objIndex = empRequestData.findIndex((obj => obj.empId == popData.empId));
    empRequestData[objIndex].email = email
    setWholeNftData( empRequestData )
  }
  
  useEffect( ()=> {
    const jwtToken = localStorage.getItem( "token" )
    axios.get( "http://193.203.162.181:4000/ntf?ntfType=cEmpRequest", { headers : { token : jwtToken } } ).then( ntfData => {
      const responseData = ntfData.data.data
      console.log(responseData);
      setWholeNftData( responseData )
    } )
    .catch( err => alert( err ) )
  }, [] )
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav style={{ justifyContent: "space-between", width: "100%", alignItems : "center", top : "0rem"}}>
          <NavIcon> 
              <FaIcons.FaBars onClick={showSidebar} />
          </NavIcon>
          <NavIcon > 
            <div style={{ display: "flex", margin : "1rem", background : "black" }}>
            <BootstrapSwitchButton checked={true} onlabel="On" offlabel='Off'  onstyle="success" offstyle="danger"/>
            </div>
            <div style={{ display: "flex" }}>
            <BsFillBagPlusFill size={26}/>
            </div>
            <div style={{ display: "flex", margin : "1rem" }}>
              { !empNtf ? <VscRequestChanges onClick={showRightSidebar} /> : <VscGitPullRequestGoToChanges onClick={showRightSidebar}/> }
            </div>
            <BsBell/>
            <div style={{ display: "flex", alignItems: "center", margin: "1rem" }} onClick={showNavbar}>
            <Avatar
              className="mr-2"
              name={"Kalyan Dahake"}
              size="35"
              color='#fff'
              fgColor='black'
              round={true}
            />
            <IoMdArrowDropdown/>
            </div>
          </NavIcon>
        </Nav>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon>
            <FaIcons.FaBars onClick={showSidebar}/>
            </NavIcon>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
        <RightSidebarNav rightSidebar={rightSidebar}>
          <RightSidebarWrap>
            <BlurBox transActive={popUp} style={blurBoxStyle}>
              <label onClick={()=>setPopUp(!popUp)} style={{ color : "white", position: "absolute", left : "25rem", fontSize : "1.1rem", fontWeight : "bold", top : "1rem" }}>*Close*</label>
              <div style={{ margin : "1rem", padding : "1rem", width: "100%", height: "100%"}}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <label style={{ fontSize : "1.1rem", fontWeight : "bold", margin : ".5rem", color : "white"  }} >Name:</label>
                  <label style={{ fontSize : "1.1rem", fontWeight : "bold", margin : ".5rem", color : "white" }} >{popData.name}</label>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <label style={{ fontSize : "1.1rem", fontWeight : "bold", margin : ".5rem", color : "white"  }} >Mobile:</label>
                  <label style={{ fontSize : "1.1rem", fontWeight : "bold", margin : ".5rem", color : "white" }} >{popData.mobile}</label>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <label style={{ fontSize : "1.1rem", fontWeight : "bold", margin : ".5rem", color : "white"  }} >Private Email:</label>
                  <label style={{ fontSize : "1.1rem", fontWeight : "bold", margin : ".5rem", color : "white" }} >{popData.privateEmail}</label>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <label style={{ fontSize : "1.1rem", fontWeight : "bold", margin : ".5rem", color : "white"  }} >Official Email:</label>
                  <input style={{ margin : ".5rem" }} value={email} onChange={e => setEmail(e.target.value)} disabled={ popData.email ? true : false }/>
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems : "center" }}>
                  <button onClick={popUpRequestHandler} style={{ margin: ".5rem" }}disabled={ popData.email ? true : false }>Approve</button>
                  { popData.email ? <h6 style={{ color : "white", paddingTop : "1.3%", paddingLeft : "5%"  }}>Already Approved</h6> : null }
                </div>
              </div>
            </BlurBox>
            <div  style={{ display : "flex", flexDirection : "column", alignItems : "center", padding : "1rem", height : "30rem" }}>
              { empRequestData.length > 0 ?
              empRequestData.map( 
                ( emp, index ) =>
                  <div key={index} style={{ width : "100%", height : "11.5rem", backgroundColor : "#64CCC5", margin : "1rem", padding : "1rem"}} onClick={()=>popUpHandler(emp)}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <label style={{ fontSize : "1.1rem", fontWeight : "bold", margin : ".5rem", color : "white"  }} >Name:</label>
                      <label style={{ fontSize : "1.1rem", fontWeight : "bold", margin : ".5rem", color : "white" }} >{ ( emp.name && emp.name.length > 5 ) ? ( emp.name.slice(0, 8) + "..." ) : emp.name }</label>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <label style={{ fontSize : "1.1rem", fontWeight : "bold", margin : ".5rem", color : "white"  }} >Mobile:</label>
                      <label style={{ fontSize : "1.1rem", fontWeight : "bold", margin : ".5rem", color : "white" }} >{( emp.mobile && emp.mobile.length > 5) ? ( emp.mobile.slice(0, 8) + "..." ) : emp.mobile}</label>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <label style={{ fontSize : "1.1rem", fontWeight : "bold", margin : ".5rem", color : "white"  }} >Email:</label>
                      <label style={{ fontSize : "1.1rem", fontWeight : "bold", margin : ".5rem", color : "white" }} > { ( emp.privateEmail && emp.privateEmail.length > 5 )? ( emp.privateEmail.slice(0, 8) + "..." ) : emp.privateEmail }</label>
                    </div>
                  </div>
                ) : null}
            </div>
          </RightSidebarWrap>
        </RightSidebarNav>
      </IconContext.Provider>
      { navBar ? <Dropdown/> : null }
      <Outlet/>
    </>
  );
};

export default Sidebar;
