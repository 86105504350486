
import React, { useState, useRef } from 'react'
import { useFormik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import axios from 'axios';
import { Link } from "react-router-dom"

import "./category.css"
import PageLocation from "../assets/PageLocation.asset";
const jwtToken = localStorage.getItem( "token" )
const CreateCategory = ( { popUp } ) => {
    const formRef = useRef(null);

    const resetFileInput = () => {
        if (formRef.current) formRef.current.reset(); // Reset the form
    };
    const createCategory = async( reqBody ) => {
    const host = "http://193.203.162.181:4000"
    const url = host + `/category`
    const { data } = await axios.post( url, reqBody ,{ headers : { "token" : jwtToken, 'Content-Type': 'multipart/form-data' } });
    if( !data.status ) alert( "Server Error" )
    else alert ( data.message )
    }
    const formik = useFormik({
        initialValues: {
          name: "",
          file: ""
        },
        validationSchema: Yup.object(
            {
                name: Yup.string()
                .required('A Name is required'),
                // .min(2, "Mininum 2 characters")
                // .max(15, "Maximum 15 characters"),
                file : Yup.mixed()
                .required( "You must select a file to upload" )
                .test( "fileSize", "FIle is too large", ( value ) => {
                    return value && value.size <= 10485760;
                } )
            }
        ),
        onSubmit: async( valueObj, { resetForm } ) => {
            const formData = new FormData();
            formData.append('file', valueObj.file);
            formData.append('name', valueObj.name);
            await createCategory( formData )
            resetFileInput()
            resetForm()
        }
    });
    return ( 
        <div className='create-container' style={{ marginLeft : "5rem" }}>
        <div>
            <PageLocation header="Famer Shop" model="Categories" address="Create Category"/>
            <hr/>
        </div>
        <form onSubmit={formik.handleSubmit} ref={formRef}>        
            <div className='createInput'>
                <div className='emp-inputBox'>
                    <label><h5>Category Name</h5><span class="required">*</span></label>
                    <div class="">
                        <input type="text" name="name" class="form-control" value={formik.values.name} onChange={formik.handleChange} placeholder="Please Enter Name" autoComplete="off"/>    
                        {( formik.errors.name && formik.touched.name  ) ? ( <p>{formik.errors.name}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Thumbnail</h5> <span class="required">*</span></label>
                    <div class="">
                        <input type="file" name="file" class="form-control"
                            onChange={(event) => { formik.setFieldValue('file', event.currentTarget.files[0])}}
                        />    
                        {( formik.errors.file && formik.touched.file  ) ? ( <p>{formik.errors.file}</p> ) : <p></p>}
                    </div>
                </div>
            </div>
            <div className='createInput'>
                <div className='emp-inputBox-submit'>
                    <div className="empty-space"></div>
                    <button type="submit"  class="btn btn-success">Save</button>
                    &nbsp;&nbsp;
                    <Link to="/main/category" onClick={ ()=>popUp() } ><button type="submit" class="btn btn-danger">Cancel</button></Link>
                </div>
            </div>
        </form>
        </div>
    )
}

export default CreateCategory;
