
import React, { useState } from 'react'
import { useFormik } from "formik";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import axios from 'axios';
import { Link } from "react-router-dom"

import "./similarProduct.css"
import PageLocation from "../assets/PageLocation.asset";
const jwtToken = localStorage.getItem( "token" )
const CreateEmp = ( { popUp } ) => {
    const createEmp = async( reqBody ) => {
        const host = "http://193.203.162.181:4000"
        const url = host + `/similarProducts`
        const { data } = await axios.post( url, reqBody ,{ headers : { token : jwtToken } });
        if( !data.status ) alert( "Server Error" )
        else alert ( data.message )
    }
    const formik = useFormik({
        initialValues: {
          name: ""
        },
        validationSchema: Yup.object(
            {
                name: Yup.string()
                .required('A Name is required'),
                // .min(2, "Mininum 2 characters")
                // .max(15, "Maximum 15 characters"),
            }
        ),
        onSubmit: async( valueObj ) => {
            console.log(valueObj);
            await createEmp( valueObj )
            formik.resetForm()
        }
    });
    return ( 
        <div className='create-container' style={{ marginLeft : "5rem" }}>
        <div>
            <PageLocation header="Famer Shop" model="Similar Products" address="Create"/>
            <hr/>
        </div>
        <form onSubmit={formik.handleSubmit}>        
            <div className='createInput'>
                <div className='emp-inputBox'>
                    <label><h5>Full Name</h5><span class="required">*</span></label>
                    <div class="">
                        <input type="text" name="name" class="form-control" value={formik.values.name} onChange={formik.handleChange} placeholder="Please Enter Name" autoComplete="off"/>    
                        {( formik.errors.name && formik.touched.name  ) ? ( <p>{formik.errors.name}</p> ) : <p></p>}
                    </div>
                </div>
            </div>
            <div className='createInput'>
                <div className='emp-inputBox-submit'>
                    <div className="empty-space"></div>
                    <button type="submit"  class="btn btn-success">Save</button>
                    &nbsp;&nbsp;
                    <Link to="/main/similarProducts" onClick={ ()=>popUp() } ><button type="submit" class="btn btn-danger">Cancel</button></Link>
                </div>
            </div>
        </form>
        </div>
    )
}

export default CreateEmp;
