export const units =[
    {
      "lable" : "Kilogram",
      "value" : "kg"
    },
    {
      "lable" : "Gram",
      "value" : "g"
    },
    {
      "lable" : "Milligram",
      "value" : "mg"
    },
    {
      "lable" : "Ounce",
      "value" : "oz"
    },
    {
      "lable" : "Pound",
      "value" : "lb"
    },
    {
      "lable" : "Cubic Meter",
      "value" : "mCube"
    },
    {
      "lable" : "Cubic Centimeter",
      "value" : "cmCube"
    },
    {
      "lable" : "Liter",
      "value" : "L"
    },
    {
      "lable" : "Milliliter",
      "value" : "mL"
    },
    {
      "lable" : "Gallon",
      "value" : "gal"
    },
    {
      "lable" : "Quart",
      "value" : "qt"
    },
    {
      "lable" : "Pint",
      "value" : "pt"
    },
    {
      "lable" : "Fluid Ounce",
      "value" : "flOz"
    }
  ]