
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { RiDeleteBin5Line } from "react-icons/ri"
import axios from "axios";

import PageLocation from "../assets/PageLocation.asset";
import SearchBar from "./SerachBar.component"
import Table from "../assets/Table.asset"
import SideBarStore from "../zastand/SideBar.zastand"
import BlurBox from "../tools/BlurBox";
import CreateEmp from "./CreateCustomer.component";
import UpdateEmp from "./UpdateCustomer.component";

const Customer = () => {
  const [marginForSideBar, setMargin] = useState("0%")
  const [leftPopUp, setLeftPopUp] = useState("33rem")
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [ createState, setCreateState ] = useState( null )
  const [ popUp, setPopUp ] = useState( false )
  const [ editData, setEditData ] = useState( null )
  const [ blur, setBlur ] = useState( "" )
  const sidebarStatus = SideBarStore((state) => state.sideBar);
  useEffect(() => { 
    if( sidebarStatus ){
      setMargin("14%")
      setLeftPopUp( "37rem" )
    }else{
      setMargin("0%")
      setLeftPopUp( "33rem" )
    }
   }, [sidebarStatus])

  const fetchUsers = async (page, size = perPage, searchQuery = "" ) => {
    setLoading(true);
    const host = "http://193.203.162.181:4000"
    const url = host + `/customers?page=${page}&limit=${size}` + searchQuery
    const response = await axios.get(url);

    setData(response.data.data);
    setTotalRows(response.data.total);
    setLoading(false);
  };
  useEffect(() => { fetchUsers(1) }, []);

  const handleDelete = useCallback(
    row => async () => {
      const host = "http://193.203.162.181:4000"
      const url = host + `/emp?empId=${row.id}`
      const { data } = await axios.delete( url );
      if( !data.status ) alert( "Server Error" )
      else alert ( data.message )
      fetchUsers( currentPage, perPage )
    },
    [currentPage, perPage, totalRows]
  );
  const handleEdit = useCallback(
    row => async () => {
      console.log(row);
      setEditData( row )
      handleEditPopUp()
    },
    [currentPage, perPage, totalRows]
  );
  const columns = useMemo(
    () => [
      {
        name: "No.",
        selector: (row, i) => i + 1,
        width: "10%"
      },
      {
        name: "Name",
        selector: (row, i) => row.name,
        width: "30%"
      },
      {
        name: "Mobile",
        selector: (row, i) => row.mobile,
      },
      {
        name: "Email",
        selector: (row, i) => row.email,
      },
      {
        cell: row => {
          return (<>
            <button class="btn btn-sm btn-success action-edit" onClick={handleEdit(row)}>Manage</button>
            <button class="btn btn-sm btn-danger action-delete" onClick={handleDelete(row)}><RiDeleteBin5Line /></button>
          </>)
        },
        width: "10%"
      }
    ],
    [handleDelete]
  );
  const handlePageChange = page => {
    fetchUsers(page);
    setCurrentPage(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    fetchUsers(page, newPerPage);
    setPerPage(newPerPage);
  };
  const handleSearchBar = async ( searchQuery ) => {
    fetchUsers( 1, 10, searchQuery );
  }
  const handleCreatePopUp = () => {
    setEditData( null )
    setPopUp( !popUp )
    if( popUp ) {
      setBlur( null )
      fetchUsers( currentPage, perPage )
    }
    else {
      setBlur( "blur(.1rem)"  )
      setCreateState( true )
    }
  }
  const handleEditPopUp = () => {
    setPopUp( !popUp )
    if( popUp ) {
      setBlur( null )
      fetchUsers( currentPage, perPage )
    }
    else {
      setBlur( "blur(.1rem)"  )
      setCreateState( false )
    }
  }
  const blurBoxStyle = {
    top : "12rem",
    left: leftPopUp,
    position:"fixed", 
    width: "53rem",
    height: "30.8rem", 
    backgroundColor: "#F5F5F5", 
    borderRadius: ".8rem"
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: marginForSideBar, padding: "2rem"}}>
      <BlurBox transActive={ popUp } style={ blurBoxStyle }>
        { editData == null ? <CreateEmp popUp={ handleEditPopUp } /> : <UpdateEmp popUp={ handleEditPopUp } initialData={ editData }/> } 
      </BlurBox>
      <div style={{ width : "100%", height: "100%",  filter: blur }}>
      <PageLocation header="Farmer Shop" model="Customers" address="Customer List"/>
      <SearchBar model="Customers" searchquery={handleSearchBar} popUp={ handleCreatePopUp }/>
      <Table 
        columns={columns} 
        data={data} 
        progressPending={loading} 
        paginationTotalRows={totalRows} 
        paginationDefaultPage={currentPage} 
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        />
      </div>
    </div>
  )
};

export default Customer;
