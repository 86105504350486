import React,{ useState, useContext } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { useNavigate } from "react-router-dom"


import Logo from "../../files/logo.png"
import BgImage from "../../files/bg.jpg"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import "./Login.component.css"
import { authenticateSocket } from "../../socket"

const Login = () => {
    const navigate = useNavigate();
    const [ visiblePassword, setVisiblePassword ] = useState( false )
    const loginRequest = async( reqBody ) => {
        const host = "http://193.203.162.181:4000"
        const url = host + `/admin/login`
        const { data } = await axios.post( url, reqBody);
        if( !data.status ) alert( "Server Error" )
        else if( data.status && data.success ) {
            localStorage.setItem( "token",data.token )
            localStorage.setItem( "clientId",data.data.mobile )
            localStorage.setItem( "name",data.data.name )
            localStorage.setItem( "roleName",data.data.roleName )
            await authenticateSocket( data.token, data.data.mobile )
            localStorage.setItem( "rToken",data.refreshToken )
            navigate( "main" )
        }else alert(data.message)
    console.log(data);
    }
    const formik = useFormik({
        initialValues: {
          username: "",
          password: ""
        },
        validationSchema: Yup.object(
           {}
        ),
        onSubmit: async( valueObj ) => {
            console.log(valueObj);
            await loginRequest( valueObj )
        }
    });
    return (
        <div className='loginContainer'>
        <div className='extraloginBar'/>
        <div className='loginFormContainer'>
        <div className='loginLogo'>
            <img src={ Logo }/>
        </div>
        <div className='loginWelcome'>
            <h1>Log In</h1>
            <h5>Welcome to Farma Store</h5>
        </div>
        <form onSubmit={formik.handleSubmit}>
            <div className='loginForm'>
            <div className='loginFormField' > 
                <label><h5>Farma Email</h5></label>
                <input type="text" name="username" className="form-control" value={formik.values.username} onChange={formik.handleChange} placeholder="Enter Username" autoComplete="off"/>    
            </div>
            <div className='loginFormField'>
                <div className='loginPassword'><label><h5>Password</h5></label>
                <div onClick={ ()=> setVisiblePassword( !visiblePassword ) } >{ visiblePassword ? <AiFillEye size={25}/> : <AiFillEyeInvisible size={25}/> }</div>
                </div>
                <input type={ visiblePassword ? "text" : "password" } name="password" className="form-control" value={formik.values.password} onChange={formik.handleChange} placeholder="Please Enter Password" autoComplete="off"/>  
            </div>
            <div className='loginButton'>
                <button type='submit' >Login</button>
            </div>
            </div>
        </form>
        <div className='loginForget'>
            <h6>Forget password ?</h6>
        </div>
        </div>
        <div className='loginFormImage'>
            <img src={ BgImage } />
        </div>
    </div>
    )
}

export default Login
