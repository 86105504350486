

import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom"
import StoreLocation from "../zastand/location.zastand"

import PageLocation from "../assets/PageLocation.asset";
const jwtToken = localStorage.getItem( "token" )
const host = "http://193.203.162.181:4000"

const CreateEmp = (  ) => {
    const navigate = useNavigate();
    const { lat, lng, setLat, setLng, setStoreData, storeData } = StoreLocation()
    const [ countryData, setCountryData] = useState( [] )
    const [ stateData, setStateData ] = useState( [] )
    const [ cityData, setCityData ] = useState( [] )
    const [ createState, setCreateState ] = useState( true )

    const create = async( reqBody ) => {
        console.log("create______________");
        const url = host + `/store`
        delete reqBody[ "id" ]
        const { data } = await axios.post( url, reqBody ,{ headers : { token : jwtToken } });
        if( !data.status ) alert( "Server Error" )
        else alert ( data.message )
        navigate( "/main/store" )
    }

    const updateData = async( reqBody ) => {
        console.log("Update________________");
        const url = host + `/store?storeId=${reqBody.id}`
        const { data } = await axios.put( url, reqBody ,{ headers : { token : jwtToken } });
        if( !data.status ) alert( "Server Error" )
        else alert ( data.message )
        navigate( "/main/store" )
    }

    const formik = useFormik({
        initialValues: {
            name : "",
            address : "",
            landmark : "",
            countryId : "",
            stateId : "",
            cityId : "",
            pinCode: "",
            lat : "",
            lng : ""
        },
        validationSchema: Yup.object(
            {
                name: Yup.string().required(),
                address: Yup.string().required(),
                landmark: Yup.string().required(),
                countryId: Yup.number().required(),
                stateId: Yup.number().required(),
                cityId: Yup.number().required(),
                pinCode: Yup.number().required(),
                lat : Yup.number().required("Click on Button To get Latitute"),
                lng : Yup.number().required("Click on Button To get Latitute")
            }
        ),
        onSubmit: async( valueObj ) => {
            console.log(valueObj);
            valueObj[ "coordinates" ] = { type: 'Point', coordinates: [ valueObj[ "lng" ], valueObj["lat"] ] }
            if(createState) await create( valueObj )
            else await updateData( valueObj )
            
            setLat(null);
            setLng(null);
            setStoreData({ })
            formik.resetForm()
        }
    });

    useEffect( ()=> {
        axios.get( host + "/location/country", { headers : { token : jwtToken } } )
        .then( ( { data } ) => { setCountryData( data.data ) } )
        .catch( err => alert( err ))
        if( storeData.hasOwnProperty("id") )setCreateState( false )
        else setCreateState( true )
    }, [] )

    useEffect( ()=> {
        if( formik.values.countryId ) {
             axios.get( host + `/location/states?countryId=${formik.values.countryId}`, { headers : { token : jwtToken } } )
            .then( ( { data } ) => { setStateData( data.data ) } )
            .catch( err => alert( err ))
        }
    }, [formik.values.countryId] )

    useEffect( ()=> {
        if( formik.values.stateId ) {
             axios.get( host + `/location/cities?stateId=${formik.values.stateId}`, { headers : { token : jwtToken } } )
            .then( ( { data } ) => { setCityData( data.data ) } )
            .catch( err => alert( err ))
        }
    }, [formik.values.stateId] )

    useEffect( () => {
        const obj = storeData
        obj[ "lat" ] = lat
        obj[ "lng" ] = lng
        formik.setValues( obj )
    }, [ lat, lng, storeData ] )

    const handleStoreData = ( ) => {
        setStoreData( formik.values )
    }
    const handleCancel = () => {
        setLat(null);
        setLng(null);
        setStoreData({ })
        formik.resetForm()
    }
    return ( 
        <div className='storeCreate-container'>
        <div className='page-container'>
            <PageLocation header="Farmer Shop" model="Store" address={ createState ? "Create" : "Update" }/>
            <hr/>
        </div>
        <form onSubmit={formik.handleSubmit} style={{ marginTop : "3%" }}>        
        <div className="mb-3 row">
            <label  className="col-sm-2 col-form-label">Store Name <span className="required">*</span></label>
            <div className="col-sm-6">
            <input type="text" className="form-control" id="name"  name="name" value={ formik.values.name } onChange={formik.handleChange}/>    
            </div>
        </div>
        
        <div className="mb-3 row">
            <label className="col-sm-2 col-form-label">Full Address <span className="required">*</span></label>
            <div className="col-sm-6">
            <input type="text" className="form-control" id="address" name="address" value={ formik.values.address } onChange={formik.handleChange}/>
            </div>
        </div>
        <div className="mb-3 row">
            <label  className="col-sm-2 col-form-label">Landmark <span className="required">*</span></label>
            <div className="col-sm-6">
            <input type="text" className="form-control" id="landmark" name="landmark" value={ formik.values.landmark } onChange={formik.handleChange}/>
            </div>
        </div>
        <div className="mb-3 row">
            <label className="col-sm-2 col-form-label">Country<span className="required">*</span></label>
            <div class="col-sm-6">
                <select type="text" name="countryId" class="form-control" value={formik.values.countryId} onChange={formik.handleChange} >
                    <option>Please Select Country</option>    
                    { countryData && countryData.map( (country, index) => <option key={index} value={country["id"]} >{country.name}</option> ) }
                </select>    
                {( formik.errors.countryId && formik.touched.countryId  ) ? ( <p>{formik.errors.countryId}</p> ) : <p></p>}
            </div>
        </div>
        <div className="mb-3 row">
            <label  className="col-sm-2 col-form-label">State<span className="required">*</span></label>
            <div className="col-sm-6">
            <select type="text" name="stateId" class="form-control" value={formik.values.stateId} onChange={formik.handleChange} >
                <option>Please Select State</option>    
                    { stateData && stateData.map( (state, index) => {
                        if( stateData && stateData.stateId == state["id"]) return( <option key={index} selected value={state["id"]} >{state.name}</option> )
                        return( <option key={index} value={state["id"]} >{state.name}</option> )
                    } ) }
                </select>    
                {( formik.errors.stateId && formik.touched.stateId  ) ? ( <p>{formik.errors.stateId}</p> ) : <p></p>}
            </div>
        </div>
        <div className="mb-3 row">
            <label className="col-sm-2 col-form-label">City<span className="required">*</span></label>
            <div className="col-sm-6">
            <select type="text" name="cityId" class="form-control" value={formik.values.cityId} onChange={formik.handleChange} >
                <option>Please Select City</option>    
                { cityData && cityData.map( (city, index) => <option key={index} value={city["id"]} >{city.name}</option> ) }
            </select>    
            {( formik.errors.cityId && formik.touched.cityId  ) ? ( <p>{formik.errors.cityId}</p> ) : <p></p>}
          </div>
        </div>
        <div className="mb-3 row">
            <label className="col-sm-2 col-form-label">Pin Code <span className="required">*</span></label>
            <div className="col-sm-6">
            <input type="text" className="form-control" id="pinCode" name="pinCode" value={ formik.values.pinCode } onChange={formik.handleChange}/>
            </div>
        </div>
        <div className="mb-3 row">
            <label  className="col-sm-2 col-form-label">Store Co-ordinates <span className="required">*</span></label>
            <div className="col-sm-6">
            <Link to="/main/store/loc" onClick={handleStoreData}>
                <button className='btn btn-dark' type='button' > { lat ? "Click to Update Store Co-ordinates" : "Click to Get Store Co-ordinates" } </button>
            </Link>
            </div>
        </div>
        <div className="mb-3 row">
            <label  className="col-sm-2 col-form-label">Latitude <span className="required">*</span></label>
            <div className="col-sm-6">
            <input type="text" className="form-control" id="lat" name="lat" value={formik.values.lat} onChange={formik.handleChange} disabled/>
            {( formik.errors.lat && formik.touched.lat  ) ? ( <p>{formik.errors.lat}</p> ) : <p></p>}
            </div>
        </div>
        <div className="mb-3 row">
            <label className="col-sm-2 col-form-label">Longitude <span className="required">*</span></label>
            <div className="col-sm-6">
            <input type="text" className="form-control" id="lng" name="lng" value={formik.values.lng} onChange={formik.handleChange} disabled/>   
            {( formik.errors.lng && formik.touched.lng  ) ? ( <p>{formik.errors.lng}</p> ) : <p></p>} 
            </div>
        </div>
        
        <div className="mb-3 row">
            <div className="offset-sm-2 col-sm-6">
            <button type="submit" className="btn btn-success">Save</button>
            &nbsp;&nbsp;
            <Link to="/main/store" onClick={handleCancel} ><button type="button" className="btn btn-danger">Cancel</button></Link>
            </div>
        </div>
        </form>
        </div>
    )
}

export default CreateEmp;
