import React from 'react'

const BlurBox = ( props ) => {
  return (
    <div style={{ overflow:"hidden", display: ( props.transActive ? "flex" : "none" ), overflow : 'hidden', position: "absolute", zIndex: 1 }}>
            <div style={props.style}>
                {props.children}
            </div>
      </div>
  )
}

export default BlurBox