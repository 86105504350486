import create from "zustand";
import { persist } from "zustand/middleware";

const store = (set) => ({
  jwtToken: "",
  setJwtToken: ( jwtToken ) => set({ jwtToken }),
  refreshJwtToken: "",
  setRefreshJwtToken: ( refreshJwtToken ) => set({ refreshJwtToken }),
});


const configStore = create(persist(store));

export default configStore;
