import React from "react"

const PageLocation = ( { header, model, address } ) => {
  return (
    <div style={{ display: "flex", flexDirection : "column", alignItems: "flex-start", marginTop : "5%"}}>
      <h1>{header}</h1>
      <nav>
          <ol className="breadcrumb">
              <li className="breadcrumb-item">{header} / {model}</li>
              <li className="breadcrumb-item active" aria-current="page"> { address }</li>
          </ol>
      </nav>
    </div>
  )
};
export default PageLocation;
