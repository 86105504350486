
import React, { useState } from 'react'
import { useFormik } from "formik";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import axios from 'axios';
import { Link } from "react-router-dom"

import "./employee.css"
import PageLocation from "../../assets/PageLocation.asset";
const jwtToken = localStorage.getItem( "token" )
const CreateEmp = ( { popUp, storeId } ) => {
    const createEmp = async( reqBody ) => {
        const host = "http://193.203.162.181:4000"
        const url = host + `/emp/adminCreate`
        reqBody.storeId = storeId
        const { data } = await axios.post( url, reqBody ,{ headers : { token : jwtToken } });
        if( !data.status ) alert( "Server Error" )
        else alert ( data.message )
    }
    const formik = useFormik({
        initialValues: {
          name: "",
          privateEmail: "",
          mobile: "",
          dob: undefined,
          roleId:undefined,
          email: ""
        },
        validationSchema: Yup.object(
            {
            }
        ),
        onSubmit: async( valueObj ) => {
            console.log(valueObj);
            await createEmp( valueObj )
            formik.resetForm()
            popUp()
        }
    });
    return ( 
        <div className='create-container'>
        <div >
            <PageLocation header="Store" model="Employee" address="Employee List"/>
            <hr/>
        </div>
        <form onSubmit={formik.handleSubmit}>        
            <div className='createInput'>
                <div className='emp-inputBox'>
                    <label><h5>Full Name</h5><span class="required">*</span></label>
                    <div class="">
                        <input type="text" name="name" class="form-control" value={formik.values.name} onChange={formik.handleChange} placeholder="Please Enter Name" autoComplete="off"/>    
                        {( formik.errors.name && formik.touched.name  ) ? ( <p>{formik.errors.name}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Email</h5> <span class="required">*</span></label>
                    <div class="">
                        <input type="text" name="email" class="form-control" value={formik.values.privateEmail} onChange={( e )=> formik.setFieldValue( "privateEmail", e.target.value )} placeholder="Please Enter Email" autoComplete="off"/>    
                        {( formik.errors.privateEmail && formik.touched.privateEmail  ) ? ( <p>{formik.errors.privateEmail}</p> ) : <p></p>}
                    </div>
                </div>
            </div>

            <div className='createInput'>
                <div className='emp-inputBox'>
                    <label><h5>Mobile</h5><span class="required">*</span></label>
                    <div class="">
                        <input type="text" name="mobile" class="form-control" value={formik.values.mobile} onChange={formik.handleChange} placeholder="Please Enter Mobile" autoComplete="off"/>    
                        {( formik.errors.mobile && formik.touched.mobile  ) ? ( <p>{formik.errors.mobile}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                <label><h5>DOB</h5><span class="required">*</span></label>
                <div class="">
                    <DatePicker selected={ formik.values.dob } placeholderText='Date Of Birth' onChange={( e )=> formik.setFieldValue( "dob", new Date( e ) )}/>
                    {( formik.errors.dob && formik.touched.dob  ) ? ( <p>{formik.errors.dob}</p> ) : <p></p>}
                    </div>
                </div>
            </div>

            <div className='createInput'>
                <div className='emp-inputBox'>
                    <label><h5>Official Mail</h5><span class="required">*</span></label>
                    <div class="">
                        <input type="text" name="email" class="form-control" value={formik.values.email} onChange={formik.handleChange} placeholder="Please Enter email" autoComplete="off"/>    
                        {( formik.errors.email && formik.touched.email  ) ? ( <p>{formik.errors.email}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Role Type</h5><span class="required">*</span></label>
                    <div className="">
                    <select type="text" name="roleId" class="form-control" value={formik.values.roleId} onChange={formik.handleChange} style={{ width : "13rem" }} placeholder="Store Employee" >
                        <option >Select a Role</option>
                        <option key={1} value={4}>Store Employee</option>
                        <option key={2} value={3}>Store Manager</option>
                    </select>        
                    </div>
                </div>
            </div>
            
            <div className='createInput'>
                <div className='emp-inputBox-submit'>
                    <button type="submit" className="btn btn-success">Save</button>
                    &nbsp;&nbsp;
                    <Link to={ `/main/store/emp/${storeId}` } onClick={ ()=>popUp() } ><button type="submit" className="btn btn-danger">Cancel</button></Link>
                </div>
            </div>
        </form>
        </div>
    )
}

export default CreateEmp;
