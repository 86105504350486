

import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { Link } from "react-router-dom"

import "./category.css"
import PageLocation from "../assets/PageLocation.asset";

const UpdateBrand = ( { popUp, initialData } ) => {
    const host = "http://193.203.162.181:4000"
    const fileInputRef = useRef(null);
    const formRef = useRef(null);
    const resetFileInput = () => {
        if (formRef.current) formRef.current.reset(); // Reset the form
    };
    const openFileExplorer = () => {
        if (fileInputRef.current) fileInputRef.current.click();
    };

    const update = async( reqBody ) => {
        const url = host + `/category?categoryId=${initialData[ "_id" ]}`
        const { data } = await axios.put( url, reqBody);
        if( !data.status ) alert( "Server Error" )
        else alert ( data.message )
    }
    const [selectedImage, setSelectedImage] = useState(null);
    useEffect( ()=> { 
        initialData.thumbnail = host +"/"+ initialData.thumbnail
        formik.setValues( initialData ) 
        setSelectedImage()
    }, [initialData] )
    const formik = useFormik({
        initialValues: {
            name: "",
            thumbnail: "",
            file: {}
        },
        validationSchema: Yup.object(
            {
                // name: Yup.string()
                // .required('A Name is required')
                // .min(2, "Mininum 2 characters")
                // .max(15, "Maximum 15 characters"),
                // privateEmail: Yup.string()
                // .required('A Email is required')
                // .email("Invalid email format"),
                // dob: Yup.string()
                // .required('A DOB is required')
                // .min(2, "Mininum 2 characters")
                // .max(15, "Maximum 15 characters"),
                // mobile: Yup.number()
                // .required('A Mobile number is required')
                // .test( 'maxLenght', 'Should be 10 Digit' , val => !isNaN(val) && `${val}`.length <= 10 )
                // .positive("A phone number can't start with a minus")
                // .integer("A phone number can't include a decimal point")
            }
        ),
        onSubmit: async( valueObj ) => {
            console.log(valueObj);
            const formData = new FormData();
            formData.append('file', valueObj.file);
            formData.append('name', valueObj.name);
            await update( formData )
            formik.resetForm()
            resetFileInput()
        }
    });

    
    const handleInputChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            formik.setFieldValue( "file", selectedFile )
            const reader = new FileReader();
            reader.onload = (e) => setSelectedImage(e.target.result);
            reader.readAsDataURL(selectedFile);
        } else {
            setSelectedImage(null);
        }
      };
      const handleClose = () => {
        popUp()
        resetFileInput()
        formik.resetForm()
        setSelectedImage(null)
      }
    
    return ( 
        <div className='create-container' style={{ marginLeft : "5rem" }}>
        <div >
            <PageLocation header="Farmer Shop" model="Categories" address="Category Update"/>
            <hr/>
        </div>
        <form onSubmit={formik.handleSubmit}>        
            <div className='createInput'>
                <div className='emp-inputBox'>
                    <label><h5>Category Name</h5><span class="required">*</span></label>
                    <div class="">
                        <input type="text" name="name" class="form-control" value={formik.values.name} onChange={formik.handleChange} placeholder="Please Enter Name" autoComplete="off"/>    
                        {( formik.errors.name && formik.touched.name  ) ? ( <p>{formik.errors.name}</p> ) : <p></p>}
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label class="col-sm-4 col-form-label"><h5>Category Id</h5><span class="required">*</span></label>
                    <div class="">
                    <input type="text" name="categoryCode" class="form-control" value={formik.values.categoryCode} disabled/>        
                    </div>
                </div>
            </div>
            <div className='createInput' style={{ marginTop : "1rem" }}>
                <div className='emp-inputBox'>
                    <div style={{ alignItems : "center", width : "70%" }}>
                    <img src={ selectedImage ? selectedImage : formik.values.thumbnail } style={{boxShadow: '4px 4px 4px #888', border: '2px solid #000'}} width={"40%"} alt='thumbnail'/>
                </div>
                <div style={{ justifyContent : "center", width : "80%", marginTop : "5%" }}>
                    <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleInputChange}/>     
                    <button type='button' onClick={openFileExplorer}>Click to Change Thumbnail</button>  
                </div>
                </div>
                <div className='emp-inputBox-submit'>
                    <div className="empty-space"></div>
                    <button type="submit"  class="btn btn-success">Save</button>
                    &nbsp;&nbsp;
                    <Link to="/main/category" onClick={ handleClose } ><button type="submit" class="btn btn-danger">Cancel</button></Link>
                </div>
            </div>
        </form>
        </div>
    )
}

export default UpdateBrand;
