
import React, { useState, useEffect, useRef } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { Link, useParams } from "react-router-dom"

import "./products.css"
import PageLocation from "../assets/PageLocation.asset";

const jwtToken = localStorage.getItem( "token" )
const host = "http://193.203.162.181:4000"

const CreateProduct = ( { popUp, initialData } ) => {
    const { id } = useParams()
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);
    const mediaFilesRef = useRef( [] )
    const openFileExplorer = () => {
        if (fileInputRef.current) fileInputRef.current.click();
    };
    const [ mediaData, setMediaData ] = useState( [] )
    const [ thumbnail, setThumbnail ] = useState( "" )
    const formRef = useRef(null);
    const resetFileInput = () => {
        if (formRef.current) formRef.current.reset(); // Reset the form
    };
   
   const fetchMedia = async()=> {
    const host = "http://193.203.162.181:4000"
    const url = host + `/products/getMediaList?productId=${id}`
    const { data } = await axios.get(url);
    const response = data.productData
    console.log(response);
    setThumbnail( response[ "thumbnail" ] )
    setMediaData( response[ "files" ] )
   }
   
   useEffect( ()=> {
    fetchMedia()
   }, [] )

   const handleInputChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
        const reader = new FileReader();
        reader.onload = (e) => setSelectedImage(e.target.result);
        reader.readAsDataURL(selectedFile);
    } else {
        setSelectedImage(null);
    }
  };
  
  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    mediaFilesRef.current = selectedFiles
  };

  const uploadFiles = async() => {
    const formData = new FormData();
    const selectedFiles = mediaFilesRef.current
    let filesData = new Array()
    for (let i = 0; i < selectedFiles.length; i++) {
    //   formData.append('file', selectedFiles[i]);
      filesData.push( selectedFiles[i] )
    }
    formData.append('files', filesData);
    formData.append('referenceId', id);
    console.log(formData);
    const url = host + "/products/assignFiles"
    await axios.post(url, formData)
  };

    return ( 
        <div className='product-container'>
        <div>
            <PageLocation header="Farmer Shop" model="Products" address="Media"/>
            <hr/>
        </div>
        <form ref={formRef}>              
            <div className='createInput'>
                <div className='emp-inputBox' >
                    <div style={{ alignItems : "center", width : "50%" }}>
                        <img src={ selectedImage ? selectedImage : `http://193.203.162.181:4000/${thumbnail}` } style={{boxShadow: '4px 4px 4px #888', border: '2px solid #000'}} width={"50%"} alt='thumbnail'/>
                    </div>
                    <div style={{ justifyContent : "center", width : "50%", marginTop : "5%" }}>
                        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleInputChange}/>     
                        <button type='button' onClick={openFileExplorer}>Click to Change Thumbnail</button>  
                    </div>
                </div>
                <div className='emp-inputBox'>
                    <label><h5>Images</h5> <span class="required">*</span></label>
                    <div style={{ width : "80%", display: "flex" }}>
                        <input type="file" name="description" multiple class="form-control" onChange={handleFileChange}/>    
                    </div>
                    <div style={{ marginTop : "2rem" }}></div>
                    <div>
                        <button type="button" onClick={uploadFiles} className="btn btn-success">Save</button>
                        &nbsp;&nbsp;
                        <Link to="/main/products" ><button type="submit" class="btn btn-danger">Cancel</button></Link>
                    </div>
                </div>
            </div>
        </form>
        <div className='empty-space'></div>
        <div className='row' style={{ width: "100%"}}>
            { mediaData && mediaData.map( ( image, index ) => 
            <img key={index} className='col-lg-4' style={{ border : "2px solid black", margin : "1rem", height : "20rem", width : "20rem" }} src={ ("http://193.203.162.181:4000/"+image) } width={"20%"} alt='productImage' />
             ) }
             {mediaData && mediaData.length === 0 ? <div><h5>No Media Found!</h5></div> : null}
        </div>
        </div>
    )
}

export default CreateProduct;
