import socketio from 'socket.io-client';
import React from 'react';

const socket = socketio.connect('http://193.203.162.181:4000', { autoConnect: false,  transports: ["websocket"]  });

export const SocketContext = React.createContext();

export const authenticateSocket = ( token, number ) => {
  console.log("inside, authenticateSocket");
  console.log(token, number );
  socket.auth = { token, clientId : `${number}`};
  socket.connect();
  return true
};

socket.on( "joinRoomResponse" ,( data ) => console.log(data) )

export default socket;
