import { useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Link } from "react-router-dom"
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import StoreLocZastand from "../zastand/location.zastand"

export default function Map( ) {
  const { setLat, setLng } = StoreLocZastand()
  const [ center, setCenter ] = useState( { lat: 43.45, lng: -80.49 } )
  const [selected, setSelected] = useState(null);
  const onMarkerDrag = (coord, marker) => {
    const lat = coord.latLng.lat()
    const lng = coord.latLng.lng()
    setLng( lng )
    setLat(lat)
  };

  useEffect( () => {
    setCenter( selected )
    const lat =  selected ? selected.lat : null
    const lng = selected ?  selected.lng : null
    setLng( lng )
    setLat(lat)
  }, [ selected ] )

  useEffect( () => {
    setCenter( { lat : 20.593684, lng : 78.96288 } )
  }, [ ] )

  return (
    <div>
      <div className="places-container">
        <label>Please Search Store Location</label>
        <div>
          <PlacesAutocomplete setSelected={setSelected} />
        </div>
        <div style={{ display : "flex", marginLeft : "1%" }} >
          <Link to="/main/store/create">
          <button type="button" className="btn btn-secondary">Back to Store Creation</button>
          </Link>
        </div>
      </div>

      <GoogleMap
        zoom={50}
        center={center}
        mapContainerClassName="map-container"
      >
        {selected && 
        <Marker 
          position={selected}
          draggable={true}
          onDragEnd={onMarkerDrag}
         />}
      </GoogleMap>
    </div>
  );
}

const PlacesAutocomplete = ({ setSelected }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
    console.log(address);
  };
  const casualCall = (data) => {
    console.log(data);
  }
  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className="combobox-input"
        placeholder="Search an address"
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description, terms }) => (
              <ComboboxOption key={place_id} value={description} onClick={()=>casualCall(terms)}/>
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};