import create from "zustand";
import { persist } from "zustand/middleware";

const store = (set) => ({
  sideBar: true,
  setSideBar: (sideBar) => set({ sideBar }),
  navBar: false,
  setNavBar: (navBar) => set({ navBar }),
  rightSideBar: true,
  setRightSideBar: (rightSideBar) => set({ rightSideBar }),
});

const configStore = create(persist(store));

export default configStore;
