
import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { RiDeleteBin5Line } from "react-icons/ri"
import axios from "axios";
import { useParams } from "react-router-dom"

import PageLocation from "../../assets/PageLocation.asset";
import SearchBar from "./SerachBar.component"
import Table from "../../assets/Table.asset"
import SideBarStore from "../../zastand/SideBar.zastand"
import BlurBox from "../../tools/BlurBox";
import CreateEmp from "./CreateProduct.component";
import UpdateEmp from "./UpdateProduct.component";
const host = "http://193.203.162.181:4000"
const Product = () => {
  const selectedRowValues = useRef([])
  const { id } = useParams()
  const [marginForSideBar, setMargin] = useState("0%")
  const [leftPopUp, setLeftPopUp] = useState("35rem")
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [ popUp, setPopUp ] = useState( false )
  const [ editData, setEditData ] = useState( null )
  const [ blur, setBlur ] = useState( "" )
  const [ preSelected, setPreSelected ] = useState( [] )

  const sidebarStatus = SideBarStore((state) => state.sideBar);
  useEffect(() => { 
    if( sidebarStatus ){
      setMargin("14%")
      setLeftPopUp( "26rem" )
    }else{
      setMargin("0%")
      setLeftPopUp( "20rem" )
    }
   }, [sidebarStatus])

  const fetchBrands = async (page, size = perPage, searchQuery = "" ) => {
    setLoading(true);
    const host = "http://193.203.162.181:4000"
    const url = host + `/products?page=${page}&limit=${size}&storeId=${id}` + searchQuery
    const response = await axios.get(url);

    setData(response.data.data);
    setTotalRows(response.data.total);
    setLoading(false);
  };
  
  const cleanupFunction = () => {
    const url = host + `/products/assignStore`
    axios.post( url, { storeId : id, productIds : selectedRowValues.current } )
    .then( ( { data } ) => console.log(data))
    .catch( err => console.log(err) )
  };

  useEffect(() => { 
    const url = host + `/products/productIds?&storeId=${id}`
    axios.get(url)
    .then( ( { data } ) => setPreSelected(data.data))
    .catch( err => console.log(err) )
    fetchBrands(1)
    return cleanupFunction;
   }, []);

  const handleDelete = useCallback(
    row => async () => {
      const host = "http://193.203.162.181:4000"
      const url = host + `/emp?empId=${row.id}`
      const { data } = await axios.delete( url );
      if( !data.status ) alert( "Server Error" )
      else alert ( data.message )
      fetchBrands( currentPage, perPage )
    },
    [currentPage, perPage, totalRows]
  );
  const handleEdit = useCallback(
    row => async () => {
      console.log(row);
      setEditData( row )
      handleEditPopUp()
    },
    [currentPage, perPage, totalRows]
  );
  const columns = useMemo(
    () => [
      {
        name: "No.",
        selector: (row, i) => i + 1,
        width: "8%"
      },
      {
        name: "Product Id",
        selector: (row, i) => row.productCode,
        width: "20%"
      },
      {
        name: "Name",
        selector: (row, i) => row.name,
        width: "20%"
      },
      {
        name: "Brand",
        selector: (row, i) => row.brand,
        width: "15%"
      },
      {
        name: "Category",
        selector: (row, i) => row.category,
        width: "20%"
      },
      {
        cell: row => {
          return (<>
            {/* <button class="btn btn-sm btn-success action-edit" onClick={handleEdit(row)}>Manage</button> */}
            <button class="btn btn-sm btn-danger action-delete" onClick={handleDelete(row)}><RiDeleteBin5Line /></button>
          </>)
        },
        width: "10%"
      }
    ],
    [handleDelete]
  );
  const handlePageChange = page => {
    fetchBrands(page);
    setCurrentPage(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    fetchBrands(page, newPerPage);
    setPerPage(newPerPage);
  };
  const handleSearchBar = async ( searchQuery ) => {
    fetchBrands( 1, 10, searchQuery );
  }
  const handleCreatePopUp = () => {
    setEditData( null )
    setPopUp( !popUp )
    if( popUp ) {
      setBlur( null )
      fetchBrands( currentPage, perPage )
    }
    else {
      setBlur( "blur(.1rem)"  )
    }
  }
  const handleEditPopUp = () => {
    setPopUp( !popUp )
    if( popUp ) {
      setBlur( null )
      fetchBrands( currentPage, perPage )
    }
    else {
      setBlur( "blur(.1rem)"  )
    }
  }
  const blurBoxStyle = {
    top : "3rem",
    left: leftPopUp,
    position:"fixed", 
    width: "80rem",
    height: "50rem", 
    backgroundColor: "#F5F5F5", 
    borderRadius: ".8rem"
  }
  
  const handleRowSelected = ( data ) => {
    const newArr =  data.selectedRows.map( selected => selected[ "_id" ] )
    selectedRowValues.current = newArr;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: marginForSideBar, padding: "2rem"}}>
      <BlurBox transActive={ popUp } style={ blurBoxStyle }>
        { editData == null ? <CreateEmp popUp={ handleEditPopUp } /> : <UpdateEmp popUp={ handleEditPopUp } initialData={ editData }/> } 
      </BlurBox>
      <div style={{ width : "100%", height: "100%",  filter: blur }}>
      <PageLocation header="Farmer Shop" model="Products" address="Product List"/>
      <SearchBar model="Products" searchquery={handleSearchBar} popUp={ handleCreatePopUp }/>
      <Table 
        columns={columns} 
        data={data} 
        progressPending={loading} 
        paginationTotalRows={totalRows} 
        paginationDefaultPage={currentPage} 
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        selectableRows
        onSelectedRowsChange={(data)=>handleRowSelected(data)}
        selectableRowSelected={(row) => preSelected.length > 0 && preSelected.some((ids) => ids === row[ "_id" ])}
        />
      </div>
    </div>
  )
};

export default Product;
