import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { RiDeleteBin5Line } from "react-icons/ri"

const Table = ( props ) => {
    return (
        <div style={{ width : "100%" }}>
            <DataTable
            pagination
            paginationServer
            {...props}
        />
        </div>
    );
};

export default Table;