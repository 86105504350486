
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import { authenticateSocket } from "./socket"
import Product from "./components/products/Products.component"
import SideBar from "./components/sidebar/Sidebar"
import Dashboard from "./components/dashboard/Dashboard.component"
import Brand from "./components/brands/Brand.component"
import Category from "./components/category/Category.component"
import Login from './components/auth/Login.component';
import SubCategory from "./components/subCategory/SubCategory.component"
import SimilarProducts from "./components/similarProducts/SimilarProduct.component"
import Customer from "./components/customers/Customer.component"
import Store from "./components/store/Store.component"
import MapStore from "./components/store/StoreMap.component"
import StoreForm from "./components/store/StoreForm.component"
import StoreEmp from "./components/store/employee/Employee.component"
import StoreProduct from "./components/store/products/Products.component"
import ProductMedia from "./components/products/MediaProduct.component"
import './App.css';

function App() {
  useEffect( ()=> {
    const token = localStorage.getItem( "token" )
    const clientId = localStorage.getItem( "clientId" )
    if( token && clientId ) authenticateSocket( token, clientId )
  } ,[])
  return (
    <div className="App">
      <Routes>
      <Route path='/' element={<Login/>}/>
      <Route path='/main' element={<SideBar/>}>
        <Route index element={<Dashboard />}/>
        <Route path='products' element={<Product />}/>
        <Route path='products/media/:id' element={<ProductMedia />}/>
        <Route path='brands' element={<Brand />}/>
        <Route path='category' element={<Category />}/>
        <Route path='subCategory' element={<SubCategory />}/>
        <Route path='similarProducts' element={<SimilarProducts />}/>
        <Route path='customer' element={<Customer />}/>
        <Route path='store' element={<Store />}/>
        <Route path='store/loc' element={<MapStore />}/>
        <Route path='store/create' element={<StoreForm/>} />
        <Route path='store/emp/:id' element={<StoreEmp/>} />
        <Route path='store/product/:id' element={<StoreProduct/>} />
      </Route>
    </Routes>
    </div>
  );
}

export default App;
