import create from "zustand";
import { persist } from "zustand/middleware";
import axios from "axios";

const store = (set) => ({
  empRequestData : [],
  setWholeNftData : ( empRequestData ) => set( { empRequestData } )
});

const configStore = create(persist(store));

export default configStore;
